import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { APICALL } from '../../helper/api/api';
import { object } from 'yup';
import { toastifyError, toastifySuccess } from '../../helper/Utility';
import { Spinner } from 'react-bootstrap';
import rating from '../../assets/images/nore.gif'

const RatingModal = ({ modalShow, setModalShow, objData, setObjData, getListFun }) => {
    const [isSubmit, setIsSubmit] = useState(false);
    const [value, setValue] = useState();
    useEffect(() => {
        if (objData?.rating) {
            setValue(objData?.rating)
        }
    }, [objData])
    const handleSubmit = async () => {
        setIsSubmit(true);
        try {
            const res = await APICALL('/admin/assignRating', "post", { rating: value, userId: objData?._id })
            console.log("res", res)
            if (res?.status) {
                getListFun()
                setIsSubmit(false)
                setModalShow(false)
                toastifySuccess(res?.message)
            } else {
                setIsSubmit(false)
                toastifyError("Something Wen't Wrong !!")
            }
        } catch (error) {
            setIsSubmit(false)
            console.log(error)
            toastifyError(error.response?.data?.message || "Something Wen't Wrong !!")
        }
    }
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                className='modal-all'
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Assign Rating
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <div>
<img style={{width:'100%'}} src={rating} alt=''/>
                    </div> */}
<div className='text-center'>
<Rating 
  className="mt-3" 
  name="half-rating" 
  defaultValue={objData?.rating} 
  precision={0.5} 
  size="large" // Add this line to increase the star size
  onChange={(event, newValue) => setValue(newValue)} 
  sx={{ fontSize: 50 }} // Set custom font size
/>
</div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='line-close-btn btn btn-secondary' onClick={() => { setModalShow(false); setValue(); setObjData(null) }}>Close</button>
                    {isSubmit
                        ? <button className='global_btn btn-primary'><Spinner style={{ height: '20px', width: '20px' }} /></button>
                        : <button className='global_btn btn btn-primary' onClick={() => handleSubmit()}>Update</button>
                    }
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default RatingModal