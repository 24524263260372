import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Button,
  CardHeader,
  CardFooter,
  Card,
  CardBody,
  Col,
  Form,
  Table,
} from "react-bootstrap";
import { imgBaseURL, toastifyError, toastifySuccess } from "../../../helper/Utility";
import { APICALL } from "../../../helper/api/api";
import AdminLoader from "../../components/AdminLoader";
import BackButton from "../BackButton";
import ConfirmationModal from "../ConfirmationModal";
import fixAvatar from "../../assets/images/users/avatar.jpg";

const UserDetail = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const res = await APICALL("transport/getTransportWithVehicles", "post", { id: data?._id });
      if (res?.status) {
        setLoading(false);
        setUser((prevUser) => ({
          ...prevUser,
          ...res?.data
        }));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  useEffect(() => {
    if (data?.role === "Transport") {
      setUser(data);
      fetchDetails();
    } else {
      setUser(data);
    }
  }, [data])


  const editDetails = (user) => {
    if (data?.role === "School") {
      navigate(`/admin/school-details-edit/${user._id}`, { state: user })
    }
    else if (data?.role === "Transport") {
      navigate(`/admin/transport-details-edit/${user._id}`, { state: user })
    }
  }

  const editVehicle = (vehicle) => {
    navigate(`/admin/vehicle-edit/${vehicle._id}`, { state: vehicle })
  }

  return (
    <>
      <Card className="card-cusotom ">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <BackButton />
              <h2 className="title-admins-table m-0">{data?.role}</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? (
                  <AdminLoader />
                ) : (
        <CardBody>
          <div className="cutoms-login-artist">
            {data?.role === "Transport" ?
                  <>
                  {user?.transporterDetails?.logo &&
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Logo</h2>
                      </Col>
                      <Col lg={2}>
                        <div className="view-admin-detials">
                          <img src={imgBaseURL() + user?.transporterDetails?.logo} alt="Logo" className="image_ui" />
                        </div>
                      </Col>
                    </Row>
                    }
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Personal Info</h2>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Name</h5>
                          <p>{user?.transporterDetails?.transporterName}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Rating</h5>
                          <p>{user?.rating}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>aboutUs</h5>
                          <p>{user?.transporterDetails?.aboutUs}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Phone</h5>
                          <p>{user?.phone}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Quick Info</h2>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Contact No</h5>
                          <p>{user?.transporterDetails?.quick_info?.contact_no}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Business Hours</h5>
                          <p>{user?.transporterDetails?.quick_info?.business_hours}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Since</h5>
                          <p>{user?.transporterDetails?.quick_info?.since}</p>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Website</h5>
                          <Link target="_blank" to={user?.transporterDetails?.quick_info?.website}>{user?.transporterDetails?.quick_info?.website}</Link>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Social Media</h2>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Facebook</h5>
                          <Link target="_blank" to={user?.transporterDetails?.social_media?.facebook}>{user?.transporterDetails?.social_media?.facebook}</Link>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Instagram</h5>
                          <Link target="_blank" to={user?.transporterDetails?.social_media?.instagram}>{user?.transporterDetails?.social_media?.instagram}</Link>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Twitter</h5>
                          <Link target="_blank" to={user?.transporterDetails?.social_media?.twitter}>{user?.transporterDetails?.social_media?.twitter}</Link>
                        </div>
                      </Col>
                      <Col lg={3} className="mb-4">
                        <div className="view-admin-detials">
                          <h5>Youtube</h5>
                          <Link target="_blank" to={user?.transporterDetails?.social_media?.youtube}>{user?.transporterDetails?.social_media?.youtube}</Link>
                        </div>
                      </Col>
                    </Row>
                
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Vehicles</h2>
                      </Col>
                      <Col lg={12} sm={12}>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Vehicle Title</th>
                              <th>Route Information</th>
                              <th>Driver Information</th>
                              <th>Maintenance Records</th>
                              <th>Insurance Details</th>
                              <th>Emergency Contact</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user?.vehicles?.map((vehicle) => (
                              <tr key={vehicle._id}>
                                <td>{vehicle.vehicleTitle}</td>
                                <td>{vehicle.routeInformation}</td>
                                <td>{vehicle.driverInformation}</td>
                                <td>{vehicle.maintenanceRecords}</td>
                                <td>{vehicle.insuranceDetails}</td>
                                <td>{vehicle.emergencyContact}</td>
                                <td>
                                  <button className="artist-btn" onClick={() => editVehicle(vehicle)}>Edit</button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  
                  </>
              : data?.role === "School" ?
                <>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Personal Info</h2>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Name</h5>
                        <p>{user?.schoolDetails?.schoolName}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Rating</h5>
                        <p>{user?.rating}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Phone</h5>
                        <p>{user?.phone}</p>
                      </div>
                    </Col>
                    <Col lg={12} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>About Us</h5>
                        <p>{user?.schoolDetails?.aboutUs}</p>
                      </div>
                    </Col>
                
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Quick Info</h2>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Contact No</h5>
                        <p>{user?.schoolDetails?.quick_info?.contact_no}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Business Hours</h5>
                        <p>{user?.schoolDetails?.quick_info?.business_hours}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Since</h5>
                        <p>{user?.schoolDetails?.quick_info?.since}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Website</h5>
                        <Link target="_blank" to={user?.schoolDetails?.quick_info?.website}>{user?.schoolDetails?.quick_info?.website}</Link>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Social Media</h2>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Facebook</h5>
                        <Link target="_blank" to={user?.schoolDetails?.social_media?.facebook}>{user?.schoolDetails?.social_media?.facebook}</Link>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Instagram</h5>
                        <Link target="_blank" to={user?.schoolDetails?.social_media?.instagram}>{user?.schoolDetails?.social_media?.instagram}</Link>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Twitter</h5>
                        <Link target="_blank" to={user?.schoolDetails?.social_media?.twitter}>{user?.schoolDetails?.social_media?.twitter}</Link>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>YouTube</h5>
                        <Link target="_blank" to={user?.schoolDetails?.social_media?.youtube}>{user?.schoolDetails?.social_media?.youtube}</Link>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Additional Details</h2>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Amenities</h5>
                        <p>{user?.schoolDetails?.amenities}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Accreditation Certifications</h5>
                        <p>{user?.schoolDetails?.accreditation_certifications}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Programs Offered</h5>
                        <p>{user?.schoolDetails?.programs_offered}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Admission Process</h5>
                        <p>{user?.schoolDetails?.admission_process}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Fee Structure & Faculty</h2>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Fee Structure</h5>
                        <p>{user?.schoolDetails?.fee_structure}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Faculty Information</h5>
                        <p>{user?.schoolDetails?.faculty_information}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Safety Features</h5>
                        <p>{user?.schoolDetails?.safety_features}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Extracurricular Activities</h5>
                        <p>{user?.schoolDetails?.extracurricular_activities}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Additional Info</h2>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>School Calendar</h5>
                        <p>{user?.schoolDetails?.school_calender}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Student-Teacher Ratio</h5>
                        <p>{user?.schoolDetails?.student_teacher_ratio}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Digital Infrastructure</h5>
                        <p>{user?.schoolDetails?.digital_infrastructure}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Nearby Landmarks</h5>
                        <p>{user?.schoolDetails?.nearby_landmarks}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Child and Budget Info</h2>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Child's Grade Select</h5>
                        <p>{user?.schoolDetails?.childs_grade_select}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Budget</h5>
                        <p>{user?.schoolDetails?.budget}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Special Programs</h5>
                        <p>{user?.schoolDetails?.special_programs}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>School Size</h5>
                        <p>{user?.schoolDetails?.school_size}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Languages and Support</h2>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Languages Offered</h5>
                        <p>{user?.schoolDetails?.languages_offered}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Special Needs Support</h5>
                        <p>{user?.schoolDetails?.special_needs_support}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Reputation & Accreditation</h5>
                        <p>{user?.schoolDetails?.reputation_accreditation}</p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="view-admin-detials">
                        <h5>Safety Environment</h5>
                        <p>{user?.schoolDetails?.safety_environment?.length > 0 ? user?.schoolDetails?.safety_environment.join(', ') : 'N/A'}</p>
                      </div>
                    </Col>
                  </Row>
                 
                  {user?.schoolDetails?.logo &&
                    <Row className="mb-md-3 mb-2">
                      <Col md={12} className="mb-2">
                        <h2>Logo</h2>
                      </Col>
                      <Col lg={2}>
                        <div className="view-admin-detials">
                          <img src={imgBaseURL() + user?.schoolDetails?.logo} alt="School Logo" className="image_ui" />
                        </div>
                      </Col>
                    </Row>
                  }
                </>
                :
                <>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Personal Info</h2>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Name</h5>
                        <p>{user?.name}</p>
                      </div>
                    </Col>
                   
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Email</h5>
                        <p>{user?.email}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Phone</h5>
                        <p>{user?.phone}</p>
                      </div>
                    </Col>
                    <Col lg={3} className="mb-4">
                      <div className="view-admin-detials">
                        <h5>Child Grade</h5>
                        <p>{user?.childGrade}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-md-3 mb-2">
                    <Col md={12} className="mb-2">
                      <h2>Avatar</h2>
                    </Col>
                    <Col lg={2}>
                      <div className="view-admin-detials">
                        <img src={user?.avatar ? imgBaseURL() + user?.avatar : fixAvatar}  alt="Avatar" className="image_ui" />
                      </div>
                    </Col>
                  </Row>
                </>
            }

          </div>
        </CardBody>
                )}
        {/* <CardFooter>
          {data?.role !== "Parent" ?
            <Button className="artist-btn" onClick={() => editDetails(user)}>Edit</Button>
            : ''}
        </CardFooter> */}
      </Card>
    </>
  );
};

export default UserDetail;
