import { AccountCircleOutlined, AddModeratorOutlined, Dashboard, FolderSharedOutlined, NotificationsActiveOutlined, PaidOutlined, PeopleAltOutlined } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

const icons = {
  Dashboard,
  FolderSharedOutlined,
  PeopleAltOutlined,
  AddModeratorOutlined,
  PaidOutlined,
  NotificationsActiveOutlined,
  AccountCircleOutlined,
};
const dashboard = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/admin/dashboard',
      icon: icons.Dashboard,
      breadcrumbs: false
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'item',
      url: `/admin/list/School`,
      icon: SchoolIcon,
      breadcrumbs: false
    },
    {
      id: 'parents',
      title: 'Parents',
      type: 'item',
      url: `/admin/list/Parent`,
      icon: SupervisorAccountIcon,
      breadcrumbs: false
    },
    {
      id: 'transporters',
      title: 'Transporters',
      type: 'item',
      url: `/admin/list/Transport`,
      icon: DirectionsBusIcon,
      breadcrumbs: false
    },
    {
      id: 'rating',
      title: 'Assign Rating',
      type: 'item',
      url: '/admin/ratings',
      icon: StarIcon,
      breadcrumbs: false
    },
    {
      id: 'subscription_plans',
      title: 'Subscription Plans',
      type: 'item',
      url: '/admin/subscription-plans',
      icon: CardMembershipIcon,
      breadcrumbs: false
    },
  ]
};

export default dashboard;
