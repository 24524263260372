import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, Row, Col, Button, CardFooter } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { imgBaseURL, toastifyError, toastifySuccess } from '../../../helper/Utility';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';
import ConfirmationModal from '../ConfirmationModal';

const SchoolDetailsEdit = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [imgList, setImgList] = useState([])

  const [modalShow, setModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const deleteImage = async () => {
    setIsSubmit(true)
    try {
      const res = await APICALL("school/removeGalleryImage", "post", { fileName: selectedData?.fileName, id: data?.schoolDetails?.userId });
      if (res?.status) {
        setIsSubmit(false)
        setModalShow(false)
        toastifySuccess(res?.message);
        const updatedImgList = imgList.filter((img) => img?._id !== selectedData?._id);
        setImgList(updatedImgList)
      }
    } catch (error) {
      setIsSubmit(false)
      console.log(error);
    }
  }


  const [formData, setFormData] = useState({
    _id: data?._id,
    schoolName: '',
    aboutUs: '',
    quick_info: {
      contact_no: '',
      business_hours: '',
      since: '',
      website: '',
    },
    social_media: {
      facebook: '',
      instagram: '',
      twitter: '',
      youtube: '',
    },
    amenities: '',
    accreditation_certifications: '',
    programs_offered: '',
    admission_process: '',
    fee_structure: '',
    faculty_information: '',
    safety_features: '',
    extracurricular_activities: '',
    school_calender: '',
    student_teacher_ratio: '',
    digital_infrastructure: '',
    nearby_landmarks: '',
    childs_grade_select: '',
    budget: '',
    special_programs: '',
    school_size: '',
    languages_offered: '',
    special_needs_support: '',
    reputation_accreditation: '',
    safety_environment: [],
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');

      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const fetchData = () => {
      const initialData = {
        _id: data?._id,
        schoolName: data?.schoolDetails?.schoolName || '',
        aboutUs: data?.schoolDetails?.aboutUs || '',
        quick_info: {
          contact_no: data?.schoolDetails?.quick_info?.contact_no || '',
          business_hours: data?.schoolDetails?.quick_info?.business_hours || '',
          since: data?.schoolDetails?.quick_info?.since || '',
          website: data?.schoolDetails?.quick_info?.website || '',
        },
        social_media: {
          facebook: data?.schoolDetails?.social_media?.facebook || '',
          instagram: data?.schoolDetails?.social_media?.instagram || '',
          twitter: data?.schoolDetails?.social_media?.twitter || '',
          youtube: data?.schoolDetails?.social_media?.youtube || '',
        },
        amenities: data?.schoolDetails?.amenities || '',
        accreditation_certifications: data?.schoolDetails?.accreditation_certifications || '',
        programs_offered: data?.schoolDetails?.programs_offered || '',
        admission_process: data?.schoolDetails?.admission_process || '',
        fee_structure: data?.schoolDetails?.fee_structure || '',
        faculty_information: data?.schoolDetails?.faculty_information || '',
        safety_features: data?.schoolDetails?.safety_features || '',
        extracurricular_activities: data?.schoolDetails?.extracurricular_activities || '',
        school_calender: data?.schoolDetails?.school_calender || '',
        student_teacher_ratio: data?.schoolDetails?.student_teacher_ratio || '',
        digital_infrastructure: data?.schoolDetails?.digital_infrastructure || '',
        nearby_landmarks: data?.schoolDetails?.nearby_landmarks || '',
        childs_grade_select: data?.schoolDetails?.childs_grade_select || '',
        budget: data?.schoolDetails?.budget || '',
        special_programs: data?.schoolDetails?.special_programs || '',
        school_size: data?.schoolDetails?.school_size || '',
        languages_offered: data?.schoolDetails?.languages_offered || '',
        special_needs_support: data?.schoolDetails?.special_needs_support || '',
        reputation_accreditation: data?.schoolDetails?.reputation_accreditation || '',
        safety_environment: data?.schoolDetails?.safety_environment || []
      };
      setFormData(initialData);
      setImgList(data?.schoolDetails?.gallery);
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await APICALL("school/updateSchoolDetails", "post", formData);
      if (res?.status) {
        setLoading(false);
        navigate('/admin/list/School');
        toastifySuccess(res?.message)
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [formImageData, setFormImageData] = useState({
    logo : null,
    gallery: [],
  });
  
  const imageHandleChange = (e) => {
    const { name, files } = e.target;
  
    if (name === "gallery") {
      setFormImageData((prevState) => ({
        ...prevState,
        gallery: Array.from(files),
      }));
      return;
    }
  
    if (name === "logo") {
      setFormImageData((prevState) => ({
        ...prevState,
        logo: files[0], 
      }));
      return;
    }
  };


  const handleImagesSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const ImagePerms = new FormData();
    ImagePerms.append('id', data?.schoolDetails?.userId);

    if (formImageData.gallery.length === 0 && !formImageData.logo) {
      setLoading(false);
      toastifyError("Please upload at least one field (either gallery or logo)");
      return;
    }
  
    if (formImageData.gallery.length > 0) {
      formImageData.gallery.forEach((file) => {
        ImagePerms.append('gallery', file);
      });
    }
  
    if (formImageData.logo) {
      ImagePerms.append('logo', formImageData.logo);
    }
  
    try {
      const res = await APICALL("school/updateSchoolGallery", "post", ImagePerms);
  
      if (res?.status) {
        setLoading(false);
        navigate(-1);
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toastifyError("Something went wrong");
    }
  };


  return (
    <>

      <Row>
      {loading ?
              <AdminLoader />
              : <>
        <Col md={8}>
          <Card className="card-cusotom card">
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
                <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                  <BackButton />
                  <h2 className="title-admins-table m-0">Edit Details</h2>
                </div>
              </div>
            </CardHeader>
              <>
                <Form onSubmit={handleSubmit}>
                  <CardBody>
                    <div className='cutoms-login-artist'>
                      <Row>
                        <div className='col-md-12'>
                          <Row>
                            <h5 className='mb-4'><b>About School</b></h5>

                            <Col md={3} className='mb-3'>
                              <Form.Group className="mb-3" controlId="schoolName">
                                <Form.Label>School Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='schoolName'
                                  value={formData.schoolName}
                                  onChange={handleChange}
                                  placeholder="Enter school name"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className='mb-3'>
                              <Form.Group className="mb-3" controlId="contact_no">
                                <Form.Label>Contact No</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='quick_info.contact_no'
                                  value={formData.quick_info.contact_no}
                                  onChange={handleChange}
                                  placeholder="Enter contact number"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className='mb-3'>
                              <Form.Group className="mb-3" controlId="business_hours">
                                <Form.Label>Business Hours</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='quick_info.business_hours'
                                  value={formData.quick_info.business_hours}
                                  onChange={handleChange}
                                  placeholder="e.g., 9 AM - 5 PM"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className='mb-3'>
                              <Form.Group className="mb-3" controlId="since">
                                <Form.Label>Since</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='quick_info.since'
                                  value={formData.quick_info.since}
                                  onChange={handleChange}
                                  placeholder="Year established"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={12} className='mb-3'>
                              <Form.Group className="mb-3" controlId="aboutUs">
                                <Form.Label>About Us</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  name='aboutUs'
                                  value={formData.aboutUs}
                                  onChange={handleChange}
                                  placeholder="Tell us about your school"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>

                        <div className='col-md-12'>
                          <h5 className='mb-4'><b>Social Links</b></h5>
                          <Row className='row row-cols-1 row-cols-sm-2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 g-3 pt-1 '>
                            <Col className='mb-3'>
                              <Form.Group className="mb-3" controlId="website">
                                <Form.Label>Website</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='quick_info.website'
                                  value={formData.quick_info.website}
                                  onChange={handleChange}
                                  placeholder="Enter website URL"
                                />
                              </Form.Group>
                            </Col>
                            <Col className='mb-3'>
                              <Form.Group className="mb-3" controlId="facebook">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='social_media.facebook'
                                  value={formData.social_media.facebook}
                                  onChange={handleChange}
                                  placeholder="Enter Facebook link"
                                />
                              </Form.Group>
                            </Col>
                            <Col className='mb-3'>
                              <Form.Group className="mb-3" controlId="instagram">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='social_media.instagram'
                                  value={formData.social_media.instagram}
                                  onChange={handleChange}
                                  placeholder="Enter Instagram link"
                                />
                              </Form.Group>
                            </Col>
                            <Col className='mb-3'>
                              <Form.Group className="mb-3" controlId="twitter">
                                <Form.Label>Twitter</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='social_media.twitter'
                                  value={formData.social_media.twitter}
                                  onChange={handleChange}
                                  placeholder="Enter Twitter link"
                                />
                              </Form.Group>
                            </Col>
                            <Col className='mb-3'>
                              <Form.Group className="mb-3" controlId="youtube">
                                <Form.Label>YouTube</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='social_media.youtube'
                                  value={formData.social_media.youtube}
                                  onChange={handleChange}
                                  placeholder="Enter YouTube link"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>

                        <div className='col-md-12'>
                          <h5 className='mb-4'><b>All Information</b></h5>
                          <Row>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="amenities">
                                <Form.Label>Amenities</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='amenities'
                                  value={formData.amenities}
                                  onChange={handleChange}
                                  placeholder="List amenities"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="accreditation_certifications">
                                <Form.Label>Accreditation Certifications</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='accreditation_certifications'
                                  value={formData.accreditation_certifications}
                                  onChange={handleChange}
                                  placeholder="Enter accreditation certifications"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="programs_offered">
                                <Form.Label>Programs Offered</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='programs_offered'
                                  value={formData.programs_offered}
                                  onChange={handleChange}
                                  placeholder="List programs offered"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="admission_process">
                                <Form.Label>Admission Process</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='admission_process'
                                  value={formData.admission_process}
                                  onChange={handleChange}
                                  placeholder="Describe admission process"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="fee_structure">
                                <Form.Label>Fee Structure</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='fee_structure'
                                  value={formData.fee_structure}
                                  onChange={handleChange}
                                  placeholder="Describe fee structure"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="faculty_information">
                                <Form.Label>Faculty Information</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='faculty_information'
                                  value={formData.faculty_information}
                                  onChange={handleChange}
                                  placeholder="Information about faculty"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="safety_features">
                                <Form.Label>Safety Features</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='safety_features'
                                  value={formData.safety_features}
                                  onChange={handleChange}
                                  placeholder="Describe safety features"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="extracurricular_activities">
                                <Form.Label>Extracurricular Activities</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='extracurricular_activities'
                                  value={formData.extracurricular_activities}
                                  onChange={handleChange}
                                  placeholder="List extracurricular activities"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="school_calender">
                                <Form.Label>School Calendar</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='school_calender'
                                  value={formData.school_calender}
                                  onChange={handleChange}
                                  placeholder="Enter school calendar"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="student_teacher_ratio">
                                <Form.Label>Student-Teacher Ratio</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='student_teacher_ratio'
                                  value={formData.student_teacher_ratio}
                                  onChange={handleChange}
                                  placeholder="Enter student-teacher ratio"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="digital_infrastructure">
                                <Form.Label>Digital Infrastructure</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='digital_infrastructure'
                                  value={formData.digital_infrastructure}
                                  onChange={handleChange}
                                  placeholder="Describe digital infrastructure"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="nearby_landmarks">
                                <Form.Label>Nearby Landmarks</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='nearby_landmarks'
                                  value={formData.nearby_landmarks}
                                  onChange={handleChange}
                                  placeholder="List nearby landmarks"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="childs_grade_select">
                                <Form.Label>Child's Grade Select</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='childs_grade_select'
                                  value={formData.childs_grade_select}
                                  onChange={handleChange}
                                  placeholder="Enter child's grade"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="budget">
                                <Form.Label>Budget</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='budget'
                                  value={formData.budget}
                                  onChange={handleChange}
                                  placeholder="Enter budget"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="special_programs">
                                <Form.Label>Special Programs</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='special_programs'
                                  value={formData.special_programs}
                                  onChange={handleChange}
                                  placeholder="Describe special programs"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="school_size">
                                <Form.Label>School Size</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='school_size'
                                  value={formData.school_size}
                                  onChange={handleChange}
                                  placeholder="Describe school size"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="languages_offered">
                                <Form.Label>Languages Offered</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='languages_offered'
                                  value={formData.languages_offered}
                                  onChange={handleChange}
                                  placeholder="List languages offered"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="special_needs_support">
                                <Form.Label>Special Needs Support</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='special_needs_support'
                                  value={formData.special_needs_support}
                                  onChange={handleChange}
                                  placeholder="Describe support for special needs"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="reputation_accreditation">
                                <Form.Label>Reputation & Accreditation</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='reputation_accreditation'
                                  value={formData.reputation_accreditation}
                                  onChange={handleChange}
                                  placeholder="Describe reputation and accreditation"
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3} className="mb-4">
                              <Form.Group className="mb-3" controlId="safety_environment">
                                <Form.Label>Safety Environment</Form.Label>
                                <Form.Control
                                  type="text"
                                  name='safety_environment'
                                  value={formData.safety_environment}
                                  onChange={handleChange}
                                  placeholder="Describe safety environment"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </Row>
                    </div>
                    <Row>
                      <Col md={12} className='text-end'>
                        <Button type="submit" className="artist-btn btn btn-primary">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>



                </Form>
              </>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='card-cusotom card'>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
                <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                  <h2 className="title-admins-table m-0">Upload Images</h2>
                </div>
              </div>
            </CardHeader>
            <Form onSubmit={handleImagesSubmit}>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <div className="form-group">
                      <label>Logo</label>
                      <input type="file" onChange={imageHandleChange} className="form-control" name="logo" />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group">
                      <label>Gallary</label>
                      <input type="file" multiple className="form-control" onChange={imageHandleChange} name="gallery" />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" className="global_btn btn btn-success" disabled={!data?.schoolDetails?.userId}>Upload</Button>
              </CardFooter>
            </Form>
          </Card>

          <Card className='card-cusotom card mt-3'>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
                <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                  <h2 className="title-admins-table m-0">Gallery</h2>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {data?.schoolDetails?.gallery.length ?
                <Row className="mb-md-3 mb-2">
                  {imgList?.map((item) => (
                    <Col lg={6} key={item._id}>
                      <div className="view-admin-detials text-center mb-3 image_box">
                        <button type='button' onClick={() => { setSelectedData(item); setModalShow(true) }} className='trash_btn'>
                          <i className='fa fa-trash'></i>
                        </button>
                        {item.fileType === 'video' ? (
                          <video controls>
                            <source src={imgBaseURL() + item.fileName} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img src={imgBaseURL() + item.fileName} alt={imgBaseURL() + item.fileName} className="image_ui" width="100%"
                          />
                        )}
                      </div>
                    </Col>
                  ))}
                </Row>
                : 
                <h5 className='text-center'>Doc Not Found</h5>
                }
            </CardBody>
          </Card>
        </Col>
        </>
}
      </Row>

      <ConfirmationModal {...{ modalShow, setModalShow, isSubmit }} fun={deleteImage} closeBtnName="Close" actionBtnName="Delete" title="Confirmation Modal" body="Are you sure you want to Delete this ?" />
    </>
  );
}

export default SchoolDetailsEdit;
