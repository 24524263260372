import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Paper,
    capitalize,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";
import { Navigate, useNavigate } from "react-router-dom";

const SubscriptionPlansList = () => {
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const naviagte = useNavigate();

    const fetchSubscriptionPlans = async () => {
        setLoading(true);
        try {
            const res = await APICALL("common/getAllPlans", "Post", {});
            if (res?.status) {
                setData(res?.plans);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscriptionPlans();
    }, []);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const editPlan = (item) =>{
        naviagte(`/admin/plan-edit/${item._id}`, {state : item})
    }


    return (
        <>
            <Paper className="table_samepattern">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                    }}
                >
                    <h1 className="title-admins-table">Subscription Plans</h1>
                    
                </div>

                <TableContainer>
                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">S.NO</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Duration In Months</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>What Included</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading
                                ? <AdminLoader />
                                :
                                <>
                                    {data?.map((item, i) => (
                                        <TableRow>
                                            <TableCell align="center">{i + 1}</TableCell>
                                            <TableCell>{item?.name}</TableCell>
                                            <TableCell>{item?.durationInMonths}</TableCell>
                                            <TableCell>{item?.price}</TableCell>
                                            <TableCell>{item?.whatIncluded}</TableCell>
                                            <TableCell>
                                                <Button className="global_btn p-2" onClick={()=>editPlan(item)}>Edit</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}

export default SubscriptionPlansList