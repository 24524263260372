import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Form, Row, Col, Button, CardFooter } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { APICALL } from '../../../helper/api/api';
import { imgBaseURL, toastifySuccess } from '../../../helper/Utility';
import ConfirmationModal from '../ConfirmationModal';
import AdminLoader from '../../components/AdminLoader';
import BackButton from '../BackButton';

const VehicleEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [imgList, setImgList] = useState([])
  const [loading, setLoading] = useState(false);
  const vehicleData = location.state;
  const [data, setData] = useState(null);

  const fetchvhecleData = async() => {
    try{
    const res = await APICALL("transport/getVehicleDetails", "post", {vehicleId : vehicleData?._id});
      if (res?.status) {
        setData((prevUser) => ({
          ...prevUser,
          ...res?.data
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchvhecleData();
    console.log("After Set Data :", data);
  },[vehicleData])


  const [modalShow, setModalShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);




  const [formData, setFormData] = useState({
    id: data?._id || '',
    userId: data?.userId || '',
    vehicleTitle: '',
    seatingCapacity: '',
    vehicleType: '',
    vehicleAge: '',
    routeInformation: '',
    driverInformation: '',
    safetyFeatures: '',
    maintenanceRecords: '',
    insuranceDetails: '',
    availabilityAides: '',
    emergencyContact: '',
    specialServices: '',
    pricingPlans: '',
    safetyTrainingInformation: '',
    operatingHours: {
      startTime: '',
      endTime: '',
    },
    gallery: [],
  });


  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "gallery") {
      setFormData((prevState) => ({
        ...prevState,
        gallery: Array.from(files),
      }));
      return;
    }

    if (name.includes('.')) {
      const [parent, child] = name.split('.');

      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const convertTo24HourFormat = (timeStr) => {
    if (!timeStr) return '';
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours < 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const convertTo12HourFormat = (timeStr) => {
    if (!timeStr) return '';
    let [hours, minutes] = timeStr.split(':').map(Number);
    const modifier = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${modifier}`;
  };

  useEffect(() => {
    const fetchData = () => {
      const initialData = {
        id: data?._id,
        userId: data?.userId,
        vehicleTitle: data?.vehicleTitle || '',
        seatingCapacity: data?.seatingCapacity || '',
        vehicleType: data?.vehicleType || '',
        vehicleAge: data?.vehicleAge || '',
        routeInformation: data?.routeInformation || '',
        driverInformation: data?.driverInformation || '',
        safetyFeatures: data?.safetyFeatures || '',
        maintenanceRecords: data?.maintenanceRecords || '',
        insuranceDetails: data?.insuranceDetails || '',
        availabilityAides: data?.availabilityAides || '',
        emergencyContact: data?.emergencyContact || '',
        specialServices: data?.specialServices || '',
        pricingPlans: data?.pricingPlans || '',
        safetyTrainingInformation: data?.safetyTrainingInformation || '',
        operatingHours: {
          startTime: convertTo24HourFormat(data?.operatingHours?.startTime || ''),
          endTime: convertTo24HourFormat(data?.operatingHours?.endTime || ''),
        },
        gallery: [],
      };
      setImgList(data?.gallery)
      setFormData(initialData);
    };

    fetchData();
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const perms = new FormData();

      perms.append('_id', formData?._id);

      if (formData.gallery.length > 0) {
        formData.gallery.forEach((file) => {
          perms.append('gallery', file);
        });
      }

      perms.append('id', formData.id);
      perms.append('userId', formData.userId);
      perms.append('vehicleTitle', formData.vehicleTitle);
      perms.append('seatingCapacity', formData.seatingCapacity);
      perms.append('vehicleType', formData.vehicleType);
      perms.append('vehicleAge', formData.vehicleAge);
      perms.append('routeInformation', formData.routeInformation);
      perms.append('driverInformation', formData.driverInformation);
      perms.append('safetyFeatures', formData.safetyFeatures);
      perms.append('maintenanceRecords', formData.maintenanceRecords);
      perms.append('insuranceDetails', formData.insuranceDetails);
      perms.append('availabilityAides', formData.availabilityAides);
      perms.append('emergencyContact', formData.emergencyContact);
      perms.append('specialServices', formData.specialServices);
      perms.append('pricingPlans', formData.pricingPlans);
      perms.append('safetyTrainingInformation', formData.safetyTrainingInformation);
      perms.append("startTime", convertTo12HourFormat(formData.operatingHours.startTime));
      perms.append("endTime", convertTo12HourFormat(formData.operatingHours.endTime));

      const res = await APICALL("transport/addVehicle", "post", perms);

      if (res?.status) {
        setLoading(false);
        navigate(-1);
        toastifySuccess(res?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const deleteImage = async () => {
    setIsSubmit(true)
    try{
      const res = await APICALL("transport/removeGalleryImage", "post", {vehicleId : data?._id , galleryId : selectedData?._id, id: data?.userId});
      if (res?.status) {
        setIsSubmit(false)
        setModalShow(false)
        toastifySuccess(res?.message);
        const updatedImgList = imgList.filter((img) => img?._id !== selectedData?._id);
        setImgList(updatedImgList)
      }
    }catch(error){
      setIsSubmit(false)
      console.log(error);
    }
  }



  return (
    <>
      <Card className="card-cusotom card">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center" style={{ gap: '10px' }}>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              <BackButton />
              <h2 className="title-admins-table m-0">Edit Vehicle Details</h2>
            </div>
          </div>
        </CardHeader>
        {loading ? 
        <>
        <AdminLoader />
        </> : 
          <Form onSubmit={handleSubmit}>
        <CardBody>
        <div className='cutoms-login-artist'>
            <Row>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="vehicleTitle">
                  <Form.Label>Vehicle Title</Form.Label>
                  <Form.Control
                    type="text"
                    name='vehicleTitle'
                    value={formData.vehicleTitle}
                    onChange={handleChange}
                    placeholder="Enter Vehicle Title"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="seatingCapacity">
                  <Form.Label>Seating Capacity</Form.Label>
                  <Form.Control
                    type="number"
                    name='seatingCapacity'
                    value={formData.seatingCapacity}
                    onChange={handleChange}
                    placeholder="Enter Seating Capacity"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="vehicleType">
                  <Form.Label>Vehicle Type</Form.Label>
                  <Form.Control
                    type="text"
                    name='vehicleType'
                    value={formData.vehicleType}
                    onChange={handleChange}
                    placeholder="Enter Vehicle Type"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="vehicleAge">
                  <Form.Label>Vehicle Age</Form.Label>
                  <Form.Control
                    type="number"
                    name='vehicleAge'
                    value={formData.vehicleAge}
                    onChange={handleChange}
                    placeholder="Enter Vehicle Age (in years)"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="routeInformation">
                  <Form.Label>Route Information</Form.Label>
                  <Form.Control
                    type="text"
                    name='routeInformation'
                    value={formData.routeInformation}
                    onChange={handleChange}
                    placeholder="Enter Route Information"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="driverInformation">
                  <Form.Label>Driver Information</Form.Label>
                  <Form.Control
                    type="text"
                    name='driverInformation'
                    value={formData.driverInformation}
                    onChange={handleChange}
                    placeholder="Enter Driver Information"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="safetyFeatures">
                  <Form.Label>Safety Features</Form.Label>
                  <Form.Control
                    type="text"
                    name='safetyFeatures'
                    value={formData.safetyFeatures}
                    onChange={handleChange}
                    placeholder="Enter Safety Features"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="maintenanceRecords">
                  <Form.Label>Maintenance Records</Form.Label>
                  <Form.Control
                    type="text"
                    name='maintenanceRecords'
                    value={formData.maintenanceRecords}
                    onChange={handleChange}
                    placeholder="Enter Maintenance Records"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="insuranceDetails">
                  <Form.Label>Insurance Details</Form.Label>
                  <Form.Control
                    type="text"
                    name='insuranceDetails'
                    value={formData.insuranceDetails}
                    onChange={handleChange}
                    placeholder="Enter Insurance Details"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="availabilityAides">
                  <Form.Label>Availability Aides</Form.Label>
                  <Form.Control
                    type="text"
                    name='availabilityAides'
                    value={formData.availabilityAides}
                    onChange={handleChange}
                    placeholder="Enter Availability Aides"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="emergencyContact">
                  <Form.Label>Emergency Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name='emergencyContact'
                    value={formData.emergencyContact}
                    onChange={handleChange}
                    placeholder="Enter Emergency Contact"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="specialServices">
                  <Form.Label>Special Services</Form.Label>
                  <Form.Control
                    type="text"
                    name='specialServices'
                    value={formData.specialServices}
                    onChange={handleChange}
                    placeholder="Enter Special Services"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="pricingPlans">
                  <Form.Label>Pricing Plans</Form.Label>
                  <Form.Control
                    type="text"
                    name='pricingPlans'
                    value={formData.pricingPlans}
                    onChange={handleChange}
                    placeholder="Enter Pricing Plans"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="safetyTrainingInformation">
                  <Form.Label>Safety Training Information</Form.Label>
                  <Form.Control
                    type="text"
                    name='safetyTrainingInformation'
                    value={formData.safetyTrainingInformation}
                    onChange={handleChange}
                    placeholder="Enter Safety Training Information"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="operatingHoursStartTime">
                  <Form.Label>Operating Hours Start Time</Form.Label>
                  <input
                    type="time"
                    className='form-control'
                    name='operatingHours.startTime'
                    value={formData.operatingHours.startTime}
                    onChange={handleChange}
                    placeholder="Enter Start Time (e.g., 8 AM)"
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="operatingHoursEndTime">
                  <Form.Label>Operating Hours End Time</Form.Label>
                  <input
                    className='form-control'
                    type="time"
                    name='operatingHours.endTime'
                    value={formData.operatingHours.endTime}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={3} className='mb-4'>
                <Form.Group class="mb-3" controlId="gallery">
                  <Form.Label>Gallery</Form.Label>
                  <input
                    type="file"
                    name='gallery'
                    className='form-control'
                    onChange={handleChange}
                    multiple
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="d-flex mt-4">
                  {imgList?.map((image) => (
                    <div key={image?._id} className='image_box'>
                      <button type='button' onClick={() => {setSelectedData(image); setModalShow(true)}} className='trash_btn'>
                        <i className='fa fa-trash'></i>
                      </button>
                      <img
                        src={imgBaseURL() + image.fileName}
                        alt="Vehicle gallery"
                        width="150px"
                        height="150px"
                        style={{ border: '1px solid black', marginLeft: '10px' }}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            </div>
        </CardBody>
        <CardFooter>
        <Button type="submit" className="artist-btn btn btn-primary">
          Update
        </Button>
        </CardFooter>
          </Form>
        }
      </Card>

      <ConfirmationModal {...{ modalShow, setModalShow, isSubmit }} fun={deleteImage} closeBtnName="Close" actionBtnName="Delete" title="Confirmation Modal" body = "Are you sure you want to Delete this ?" />
    </>
  );
}

export default VehicleEdit;
