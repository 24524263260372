import React from "react";
import PropTypes from "prop-types";
import MainCard from "../../components/MainCard";
import { APICALL } from "../../../helper/api/api";
import { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    Paper,
    capitalize,
    InputAdornment,
    OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "../../../assets/css/admin.css";
import AdminLoader from "../../components/AdminLoader";
import { Dropdown, FormControl, Modal } from "react-bootstrap";
import { Delete, Details, Edit, MoreVert, SearchOutlined } from "@mui/icons-material";
import { useDataContext } from '../../../helper/context/ContextProvider';
import TableMSG from "../../../components/TableMSG";
import { filterByKey } from "../../../helper/Utility";
import { TABLE_PAGINATION_DROPDOWN, TABLE_ROW_PER_PAGE } from '../../../helper/Constant';
import { EyeFilled } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";

const Users = () => {
    const navigate = useNavigate()
    const {role} = useParams()
    function TablePaginationActions(props) {

        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };


    const { permisionData } = useDataContext();

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROW_PER_PAGE);
    const permisionCheck = filterByKey("userManagement", permisionData?.permissions);

    useEffect(() => {
        if (role) {
            getListFun();
        }
    }, [role]);

    const getListFun = async () => {
        setLoading(true);
        try {
            const params = { 'roles': [role] }
            const res = await APICALL("admin/getAllUsers", "post", params);
            setLoading(false);
            if (res?.status) {
                setList(res?.data?.users);
            }
            console.log(res);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, TABLE_ROW_PER_PAGE));
        setPage(0);
    };

    const filteredList = list.filter(
        (user) =>
            user?.phone?.toLowerCase().includes(search?.toLowerCase()) ||
            user?.address?.toLowerCase().includes(search?.toLowerCase())
    );

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredList.length) : 0;
    const [modalShow, setModalShow] = useState(false);
    const [objData, setObjData] = useState(null);


    const viewDetails = (row) =>{
        navigate(`/admin/user-details/${row._id}`, {state : row})
    }

    const editDetails = (row) =>{
        if(row.role === "School")
        {
            navigate(`/admin/school-details-edit/${row._id}`, {state : row})
        }
        else if(row.role === "Transport")
        {
            navigate(`/admin/transport-details-edit/${row._id}`, {state : row})
        }
    }

    return (
        <>
            <Paper className="table_samepattern">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                    }}
                >
                    <h1 className="title-admins-table">{role}s</h1>
                    <TextField
                        variant="outlined"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                        style={{ width: "300px" }}
                    />
                </div>
                {loading ? (
                    <AdminLoader />
                ) : (
                    <>
                        <TableContainer component={Paper}>

                            {
                                permisionCheck?.read ?
                                    <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">S.NO</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone</TableCell>
                                                {role !== "Parent" ? 
                                                <TableCell>Rating</TableCell>
                                                : ''}
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <>
                                            <TableBody>
                                                {(rowsPerPage > 0
                                                    ? filteredList.slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    : filteredList
                                                ).map((row, i) => (
                                                    <TableRow key={row._id}>
                                                        <TableCell component="th" scope="row" align="center">
                                                            {i + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.role == "School" ? 
                                                            <>
                                                            {row?.schoolDetails?.schoolName}
                                                            </>   
                                                            :
                                                            row?.role == "Transport" 
                                                            ? 
                                                            <>
                                                            {row?.transporterDetails?.transporterName}
                                                            </> 
                                                            :
                                                            <>
                                                            {row?.name}
                                                            </>
                                                        }
                                                            
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.phone}
                                                        </TableCell>
                                                        {row.role !== "Parent" ? 
                                                        <TableCell>{row.rating}</TableCell>
                                                        : ''}
                                                        <TableCell>
                                                            <Dropdown className="dorpdown-curtom">
                                                            <Dropdown.Toggle as={IconButton} variant="link">
                                                                <MoreVert />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {row.role !== "Parent" ? 
                                                                <Dropdown.Item  className="p-0">
                                                                <Button className="edit_btn" onClick={()=>editDetails(row)}>
                                                                    <Edit style={{ marginRight: "8px" }} />
                                                                        Edit
                                                                    </Button>
                                                                </Dropdown.Item>
                                                                 : ''}
                                                                <Dropdown.Item  className="p-0">
                                                                <Button className="edit_btn" onClick={()=>viewDetails(row)}>
                                                                    <EyeFilled style={{ marginRight: "8px" }} />
                                                                    Details
                                                                    </Button>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={TABLE_PAGINATION_DROPDOWN}
                                                        count={filteredList.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        slotProps={{
                                                            select: {
                                                                inputProps: {
                                                                    "aria-label": "rows per page",
                                                                },
                                                                native: true,
                                                            },
                                                        }}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </>

                                    </Table>
                                    :
                                    <TableMSG msg={"You Don't have permision to view this data"} type={true} />
                            }
                        </TableContainer>
                    </>
                )}
            </Paper>
        </>
    )
}

export default Users